import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import type { NavLinkProps } from '@remix-run/react'
import { NavLink } from '@remix-run/react'
import * as React from 'react'

import { cn } from '@/lib/utils'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

// https://github.com/radix-ui/primitives/issues/1158
const TooltipNavLink = React.forwardRef<
  HTMLAnchorElement,
  NavLinkProps & { linkClassName?: NavLinkProps['className'] }
>(({ className, linkClassName, ...props }, forwardedRef) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <NavLink
      {...props}
      className={(props) =>
        cn(
          className,
          typeof linkClassName === 'function'
            ? linkClassName(props)
            : linkClassName,
        )
      }
      ref={forwardedRef}
    />
  )
})
TooltipNavLink.displayName = 'TooltipNavLink'

const TooltipWrapper = ({
  content,
  children,
  tooltipContentProps,
}: {
  content: React.ReactNode | null
  children: React.ReactNode
  tooltipContentProps: React.ComponentPropsWithoutRef<
    typeof TooltipPrimitive.Content
  >
}) => {
  if (!content) {
    return <>{children}</>
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent {...tooltipContentProps}>{content}</TooltipContent>
    </Tooltip>
  )
}

TooltipWrapper.displayName = 'TooltipWrapper'

export {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipNavLink,
  TooltipWrapper,
}
